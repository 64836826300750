// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/arrow-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ow-screen-padding {\n\tpadding-left: 7vw;\n\tpadding-right: 7vw;\n}\n.ow-leave-page-fab {\n\twidth: 71px;\n\theight: 71px;\n\tborder-radius: 4px;\n\tbackground-color: #4d708c;\n\ttext-align: center;\n\tpadding: 4px 6px 0px 6px;\n\tposition: fixed;\n\tbottom: 20px;\n\tright: 20px;\n\tbox-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);\n\topacity: 1;\n\tcursor: pointer;\n\tz-index: 9999;\n}\n.ow-leave-page-fab:hover {\n\topacity: 0.9;\n\tbox-shadow: 0px 0px 20px rgba(0, 0, 0, 0.24);\n}\n.ow-leave-page-fab h3 {\n\ttext-transform: uppercase;\n\tfont-size: 0.65rem;\n\tcolor: white;\n\topacity: 1;\n\tletter-spacing: 0.7px;\n}\n.ow-leave-page-fab .ow-fab-icon {\n\twidth: 28px;\n\theight: 28px;\n\topacity: 1;\n\tbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tbackground-position: center;\n\tbackground-size: cover;\n\tbackground-repeat: no-repeat;\n\tmargin: 0px auto;\n}\n.ow-leave-page-fab.script-enabled {\n\tdisplay: none;\n}\n", ""]);
// Exports
module.exports = exports;
